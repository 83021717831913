<template>
  <div class="relative">
    <div class="bg-blur"></div>
    <div class="fg-blur"></div>
    <div class="absolute-center">
      <q-card class="form">
        <q-card-section
          class="text-center text-white text-bold"
          style="background-color:#232979;"
        >Login Admin</q-card-section>
        <q-card-section class>
          <q-input
            outlined
            v-model="user.username"
            id="id"
            placeholder="username"
            @keyup.enter="$refs.pass.focus()"
            dense
          >
            <template v-slot:append>
              <q-icon name="account_circle" @click="text = ''" class="cursor-pointer" />
            </template>
          </q-input>
          <q-input
            outlined
            v-model="user.password"
            ref="pass"
            :type="isPwd ? 'password' : 'text'"
            id="password"
            placeholder="Password"
            @keyup.enter="login()"
            dense
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </q-card-section>
        <q-card-actions align="center" class="q-py-lg">
          <q-btn
            outline
            style="width: 130px;color:#03913F;"
            label="Batal"
            @click="$router.push('/')"
            no-caps
          />
          <q-btn
            style="width: 130px;background-color:#03913F;color:white;"
            label="Masuk"
            no-caps
            @click="login()"
          />
        </q-card-actions>
      </q-card>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
export default {
  name: "Admin",
  data: function() {
    return {
      user: {},
      model: null,
      isPwd: true,
      remember: false
    };
  },
  methods: {
    login() {
      // var md5 = require('md5');
      var usr = {};
      usr.username = this.user.username;
      usr.password = md5(this.user.password);
      this.$http.post("/login", usr, {}).then(result => {
        localStorage.setItem("token", result.data);
        this.$router.push("/dashboard");
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-container {
  z-index: 2001;
  background-color: #e3f2f8;
}
.form {
  width: 300px;
}
label {
  padding-top: 10px;
  padding-bottom: 15px;
  color: #505050;
  font-weight: bold;
}
.bg-blur {
  width: 100vw;
  height: 100vh;
  background-image: url("/img/drone.jpg");
  position: absolute;
}
.fg-blur {
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(10px) brightness(0.5);
  position: absolute;
}
</style>
